// mixin to add uniq filter to Plan and Components Need table
import UPDATE_COMPONENTS_OF_NEED from '@/graphql/mutations/updateComponentsOfNeed.gql';

export default {
  data: () => ({
    flagFilterModel: [],
    flagRequestList: {},
  }),
  methods: {
    resetFlagFilter() {
      this.flagFilterModel = [];
    },
    setFlagFilterDefault() {
      if (this.$refs.flag_filter && this.$refs.flag_filter.$attrs.default) {
        const value = this.$refs.flag_filter.$attrs.default.value;
        if (Array.isArray(value)) {
          if (value.length === 2) {
            this.flagFilterModel = ['Marked', 'Unmarked'];
          } else if (value[0] === true) {
            this.flagFilterModel = ['Marked'];
          } else if (value[0] === false) {
            this.flagFilterModel = ['Unmarked'];
          }
        }
      }
    },
    onFlagFilter(filter, callback) {
      if (filter && typeof callback === 'function') {
        let filterValue = [];
        if (this.flagFilterModel.length === 2) {
          filterValue = [true, false];
        } else if (this.flagFilterModel[0] === 'Marked') {
          filterValue = [true];
        } else if (this.flagFilterModel[0] === 'Unmarked') {
          filterValue = [false];
        }
        filter.value = filterValue;
        callback(filter);
      }
    },
    onFlagClick(item) {
      if (item && item.id) {
        item.flag = !item.flag;
        this.flagRequestList[item.id] = item;
        clearTimeout(this.changeFLagTimeout);
        this.changeFLagTimeout = setTimeout(() => {
          this.changeFlag();
        }, 400);
      }
    },
    changeFlag() {
      const input = Object.values(this.flagRequestList).map(item => {
        return {
          id: item.id,
          facilityId: (item.facility && item.facility.id) || null,
          systemId: (item.system && item.system.id) || null,
          componentId: (item.component && item.component.id) || null,
          status: item.status,
          yearCompleted: item.yearCompleted,
          actualCost: item.actualCost,
          description: item.description,
          componentImageUrl: item.componentImageUrl,
          componentDocumentUrl: item.componentDocumentUrl,
          yearForImprovement: item.yearForImprovement,
          quantityOfComponents: item.quantityOfComponents,
          unitId: (item.unit && item.unit.id) || null,
          costPerUnit: item.costPerUnit,
          conditionAssessmentId: (item.conditionAssessment && item.conditionAssessment.id) || null,
          locationOfComponent: item.locationOfComponent,
          fundingSourceInvestmentId: (item.fundingSource && item.fundingSource.id) || null,
          budgetaryNotes: item.budgetaryNotes,
          internalNotes: item.internalNotes,
          componentNotes: item.componentNotes,
          softCost: item.softCost,
          flag: item.flag,
        };
      });
      this.$apollo
        .mutate({
          mutation: UPDATE_COMPONENTS_OF_NEED,
          variables: {
            input: input,
          },
        })
        .catch(error => {
          this.error = error;
        })
        .finally(() => {
          this.flagRequestList = {};
        });
    },
  },
};
