const ACTIVE = [
  {
    text: 'Flag',
    value: 'flag',
    dataType: 'string',
    width: '40px',
    fixed: true,
    type: 'slot',
  },
  {
    text: 'Facility',
    value: 'facilityName',
    type: 'select',
    dataType: 'string',
    fixed: true,
    width: '150px',
  },
  { text: 'System', value: 'systemName', type: 'select', dataType: 'string', width: '180px' },
  {
    text: 'Component',
    value: 'componentName',
    type: 'select',
    dataType: 'string',
    width: '180px',
  },
  { text: 'Improvement Year', value: 'yearForImprovement', type: 'select', dataType: 'year', width: '100px' },
  { text: 'Quantity', value: 'quantityOfComponents', type: 'number', dataType: 'number', width: '80px' },
  { text: 'Unit', value: 'unitName', type: 'select', dataType: 'string', width: '100px' },
  { text: 'Cost Per Unit', value: 'costPerUnit', type: 'number', dataType: 'currency', width: '100px' },
  { text: 'Base Amount', value: 'baseAmount', type: 'number', dataType: 'currency', width: '100px' },
  {
    text: 'Component Subtotal',
    value: 'componentSubtotal',
    type: 'number',
    dataType: 'currency',
    width: '100px',
  },
  { text: 'Soft Cost', value: 'softCost', width: '70px' },
  { text: 'Budget Amount', value: 'budgetAmount', type: 'number', dataType: 'currency', width: '120px' },
  { text: 'Budgetary Notes', value: 'budgetaryNotes', type: 'text', dataType: 'longstring', width: '180px' },
  { text: 'Description', value: 'description', type: 'text', dataType: 'longstring', width: '180px' },
  { text: 'Average Lifecycle', value: 'averageLifeCycle', type: 'text', dataType: 'string', width: '80px' },
  { text: 'Age', value: 'componentAge', type: 'number', dataType: 'number', width: '80px' },
  { text: 'Condition', value: 'conditionName', type: 'select', dataType: 'string', width: '100px', noSort: true },
  { text: 'Priority', value: 'priority', type: 'select', dataType: 'string', width: '100px' },
  { text: 'Location', value: 'locationOfComponent', type: 'text', dataType: 'longstring', width: '130px' },
  { text: 'Image', value: 'componentImageUrl', width: '100px' },
  { text: 'Document', value: 'componentDocumentUrl', width: '100px' },
  { text: 'Funding Source', value: 'fundingSourceName', type: 'select', dataType: 'string', width: '100px' },
  { text: 'Internal Notes', value: 'internalNotes', type: 'text', dataType: 'longstring', width: '180px' },
];

const COMPLETED = [
  {
    text: 'Facility',
    value: 'facilityName',
    type: 'select',
    dataType: 'string',
    fixed: true,
    width: '150px',
  },
  {
    text: 'Component',
    value: 'componentName',
    type: 'select',
    dataType: 'string',
    width: '180px',
  },
  { text: 'Year Completed', value: 'yearCompleted', type: 'select', dataType: 'year', width: '100px' },
  { text: 'Actual Cost', value: 'actualCost', type: 'number', dataType: 'currency', width: '120px' },
  { text: 'Planned Cost', value: 'budgetAmount', type: 'number', dataType: 'currency', width: '120px' },
  { text: 'Quantity', value: 'quantityOfComponents', type: 'number', dataType: 'number', width: '100px' },
  { text: 'Completion Document', value: 'componentCompleteDocUrl', width: '100px' },
  { text: 'Document', value: 'componentDocumentUrl', width: '100px' },
  { text: 'Location', value: 'locationOfComponent', type: 'text', dataType: 'string', width: '130px' },
  { text: 'Component Notes', value: 'componentNotes', type: 'text', dataType: 'longstring', width: '180px' },
];

export default {
  ACTIVE,
  COMPLETED,
};
