import Btn from '@/components/Btn/Btn.vue';
import NumberTextField from '@/components/NumberTextField/NumberTextField.vue';
import validateRules from '@/services/validateRules';
import UNITS_QUERY from '@/graphql/queries/units.gql';
import SYSTEMS_QUERY from '@/graphql/queries/systems.gql';
import CONDITION_QUERY from '@/graphql/queries/conditionAssessment.gql';
import Upload from '@/components/Upload/Upload.vue';
import { getBaseAmount, getBudgetAmount, getComponentSubtotal } from '@/services/componentBudget';
import { generateRangeArray } from '@/utils/generator';
import { COMPONENT_STATUS, COMPONENT_STATUS_LABELS } from '@/enums/componentStatus';
import { sortByName } from '@/utils/sortByName';
import Modal from '@/components/Modal/Modal.vue';
import eventHub from '@/utils/eventHub';
import ADD_COMPONENT_OF_NEED_MUTATION from '@/graphql/mutations/addComponentOfNeed.gql';
import UPDATE_COMPONENT_OF_NEED_MUTATION from '@/graphql/mutations/updateComponentOfNeed.gql';
import COMPONENTS_OF_NEEDS from '@/graphql/queries/componentsOfNeed.gql';
import ExpansionPanelHeader from '@/components/ExpansionPanelHeader/ExpansionPanelHeader.vue';

export default {
  name: 'ComponentForm',
  components: { Btn, ExpansionPanelHeader, NumberTextField, Upload, Modal },
  props: {
    formData: { type: Object, default: null },
    btnLabel: { type: String, required: true },
    readonlyFields: { type: Array },
    plan: { type: Object },
    facilities: { type: Array, default: () => [] },
    isEdit: { type: Boolean, default: false },
  },
  apollo: {
    units: {
      query: UNITS_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError ? { ...error, message: 'Something went wrong! Could not load unit list.' } : error;
      },
    },
    systems: {
      query: SYSTEMS_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError ? { ...error, message: 'Something went wrong! Could not load system list.' } : error;
      },
    },
    conditionAssessment: {
      query: CONDITION_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError ? { ...error, message: 'Something went wrong! Could not load condition assessment list.' } : error;
      },
    },
  },
  data: vm => {
    const startYear = vm.plan && vm.plan.financialInfo && parseInt(vm.plan.financialInfo.planStartYear);
    const years = startYear ? generateRangeArray(startYear, startYear + 19) : [];
    const upload =
      vm.formData && vm.formData.componentImageUrl ? new File([''], vm.formData.componentImageUrl, { type: 'image/plain' }) : null;
    const uploadDocument =
      vm.formData && vm.formData.componentDocumentUrl ? new File([''], vm.formData.componentDocumentUrl, { type: '' }) : null;

    const componentData = vm.formData
      ? { ...vm.formData, componentImage: null, componentDocument: null }
      : {
          system: null,
          facility: null,
          component: null,
          status: COMPONENT_STATUS.OPEN,
          yearCompleted: null,
          actualCost: null,
          description: null,
          componentImageUrl: null,
          componentImage: null,
          componentDocument: null,
          componentDocumentUrl: null,
          yearForImprovement: null,
          quantityOfComponents: null,
          unit: null,
          costPerUnit: null,
          baseAmount: null,
          componentSubtotal: null,
          budgetAmount: null,
          conditionAssessment: null,
          priority: null,
          locationOfComponent: null,
          fundingSource: null,
          averageLifeCycle: null,
          componentAge: null,
          budgetaryNotes: null,
          internalNotes: null,
          componentNotes: null,
          softCost: false,
          customSoftCost: null,
          flag: false,
        };
    componentData.customSoftCost = componentData.customSoftCost ?? vm.plan.financialInfo.softCostPercentage;

    return {
      ...validateRules,
      systems: [],
      statuses: [
        { name: COMPONENT_STATUS_LABELS[COMPONENT_STATUS.OPEN], value: COMPONENT_STATUS.OPEN },
        { name: COMPONENT_STATUS_LABELS[COMPONENT_STATUS.COMPLETED], value: COMPONENT_STATUS.COMPLETED },
      ],
      units: [],
      conditionAssessment: [],
      priorityList: ['Priority 1', 'Priority 2', 'Priority 3'],
      yearCompletedRules: [v => (v !== null && v !== '') || 'Year completed is Required'],
      years: years,
      upload: upload,
      uploadDocument: uploadDocument,
      quantityOfComponentsRules: [v => (v !== null && v !== '') || 'Quantity is Required'],
      unitRules: [v => !!v || 'Unit is Required'],
      actualCostRules: [validateRules.positiveNumber, validateRules.floatNumber(2)],
      costRules: [v => (v !== null && v !== '') || 'Cost is Required', validateRules.positiveNumber, validateRules.floatNumber(2)],
      facilityRules: [v => !!v || 'Facility is Required'],
      systemRules: [v => !!v || 'System is Required'],
      componentRules: [v => !!v || 'Component is Required'],
      statusRules: [v => !!v || 'Status is Required'],
      yearForImprovementRules: [v => !!v || 'Improvement Year is Required'],
      conditionAssessmentRules: [v => !!v || 'Condition at Time of Assessment is Required'],
      fundingSourceRules: [v => !!v || 'Funding Source is Required'],
      customSoftCostRules: [validateRules.numberInRange(0, 100), validateRules.floatNumber(1)],
      descriptionRules: [v => !v || (v && v.length <= 500) || 'Max 500 characters'],
      componentData,
    };
  },
  computed: {
    componentOfNeedMutation() {
      return this.isEdit ? UPDATE_COMPONENT_OF_NEED_MUTATION : ADD_COMPONENT_OF_NEED_MUTATION;
    },
    mutationInput() {
      const input = {
        facilityId: (this.componentData.facility && this.componentData.facility.id) || null,
        systemId: (this.componentData.system && this.componentData.system.id) || null,
        componentId: (this.componentData.component && this.componentData.component.id) || null,
        status: this.componentData.status,
        yearCompleted: this.componentData.yearCompleted,
        actualCost: this.componentData.actualCost,
        description: this.componentData.description,
        componentImage: this.componentData.componentImage,
        componentDocument: this.componentData.componentDocument,
        yearForImprovement: (this.componentData.yearForImprovement && this.componentData.yearForImprovement.toString()) || null,
        quantityOfComponents: this.componentData.quantityOfComponents,
        costPerUnit: this.componentData.costPerUnit,
        unitId: (this.componentData.unit && this.componentData.unit.id) || null,
        conditionAssessmentId: (this.componentData.conditionAssessment && this.componentData.conditionAssessment.id) || null,
        priority: this.componentData.priority,
        locationOfComponent: this.componentData.locationOfComponent,
        fundingSourceInvestmentId: (this.componentData.fundingSource && this.componentData.fundingSource.id) || null,
        budgetaryNotes: this.componentData.budgetaryNotes,
        internalNotes: this.componentData.internalNotes,
        componentNotes: this.componentData.componentNotes,
        softCost: this.componentData.softCost,
        customSoftCost:
          this.componentData.customSoftCost === this.plan.financialInfo.softCostPercentage ? null : this.componentData.customSoftCost,
        flag: this.componentData.flag,
        componentAge: this.componentData.componentAge,
        averageLifeCycle: this.componentData.averageLifeCycle,
      };
      if (this.isEdit) {
        input.id = this.componentData.id;
        input.componentImageUrl = this.componentData.componentImageUrl;
        input.componentDocumentUrl = this.componentData.componentDocumentUrl;
      } else {
        input.planId = (this.plan && this.plan.id) || null;
      }
      return input;
    },
    displayedSystems() {
      return Array.isArray(this.systems) ? this.systems.sort(sortByName) : [];
    },
    components() {
      const selected = this.componentData.system && this.displayedSystems.find(s => s.id === this.componentData.system.id);
      const result = selected ? selected.components || [] : [];
      return result.sort(sortByName);
    },
    isCompleted() {
      return this.componentData.status === COMPONENT_STATUS.COMPLETED;
    },
    baseAmount() {
      const { quantityOfComponents, costPerUnit } = this.componentData;
      return getBaseAmount(quantityOfComponents, costPerUnit, this.plan.financialInfo.regionalCost);
    },
    componentSubtotal() {
      const { planStartYear, inflationCostEscalation } = this.plan.financialInfo;
      return getComponentSubtotal(this.baseAmount, planStartYear, this.componentData.yearForImprovement, inflationCostEscalation);
    },
    budgetAmount() {
      return getBudgetAmount(
        this.componentSubtotal,
        this.componentData.customSoftCost ?? this.plan.financialInfo.softCostPercentage,
        this.componentData.softCost
      );
    },
    facilityList() {
      return this.facilities?.length ? this.facilities.sort(sortByName) : [];
    },
    fundingSource() {
      return this.plan?.financialInfo?.fundingSources || [];
    },
    conditionByLabel() {
      return this.conditionAssessment.reduce((accum, current) => {
        accum[current.name] = current;
        return accum;
      }, {});
    },
  },
  methods: {
    onComponentChange() {
      if (this.componentData.component !== null) {
        this.componentData.costPerUnit = this.componentData.component.costPerUnit;
        this.componentData.unit = this.componentData.component.unit;
        this.componentData.averageLifeCycle = this.componentData.component.averageLifeCycle;
      }
    },
    onSystemChange() {
      this.componentData.component = null;
      this.componentData.costPerUnit = null;
      this.componentData.unit = null;
      this.componentData.averageLifeCycle = null;
    },
    onStatusChange() {
      if (!this.isCompleted) {
        this.componentData.yearCompleted = null;
        this.componentData.actualCost = null;
        this.componentData.componentNotes = null;
      }
    },
    readQuery(store, status) {
      return store.readQuery({
        query: COMPONENTS_OF_NEEDS,
        variables: {
          planId: this.plan.id,
          status: status,
        },
      });
    },
    writeQuery(store, status, data) {
      return store.writeQuery({
        query: COMPONENTS_OF_NEEDS,
        variables: {
          planId: this.plan.id,
          status: status,
        },
        data,
      });
    },
    updateComponent(store, data) {
      this.isEdit ? this.editComponent(store, data) : this.addComponent(store, data);
    },
    editComponent(store, { data: { updateComponentOfNeed } }) {
      let snackbarConf = {
        color: 'error',
        text: `Error! Something went wrong... Please try later!`,
      };
      if (updateComponentOfNeed) {
        const data = this.readQuery(store, COMPONENT_STATUS.OPEN);
        if (data) {
          if (updateComponentOfNeed.status === COMPONENT_STATUS.COMPLETED) {
            data.componentsOfNeed = data.componentsOfNeed.filter(c => c.id !== updateComponentOfNeed.id);
            this.writeQuery(store, COMPONENT_STATUS.OPEN, data);
          } else {
            const index = data.componentsOfNeed.findIndex(c => c.id === updateComponentOfNeed.id);
            if (index !== -1) {
              data.componentsOfNeed[index] = updateComponentOfNeed;
              data.componentsOfNeed = [...data.componentsOfNeed];
            } else {
              data.componentsOfNeed.unshift(updateComponentOfNeed);
            }
            this.writeQuery(store, updateComponentOfNeed.status, data);
          }
          snackbarConf = {
            color: 'success',
            text: `Component was updated successfully!`,
          };
          this.$emit('done');
        }
      }
      eventHub.$emit('show-snackbar', snackbarConf);
    },
    addComponent(store, { data: { addComponentOfNeed } }) {
      if (addComponentOfNeed) {
        const data = this.readQuery(store, addComponentOfNeed.status);
        if (data) {
          data.componentsOfNeed.unshift(addComponentOfNeed);
          this.writeQuery(store, addComponentOfNeed.status, data);
        }
        eventHub.$emit('show-snackbar', {
          color: 'success',
          text: `Component was added successfully!`,
        });
        this.$emit('done');
      } else {
        eventHub.$emit('show-snackbar', {
          color: 'error',
          text: `Error! Something went wrong... Please try later!`,
        });
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
    onSubmit(mutate) {
      if (this.validate()) {
        this.componentData.componentImage = this.upload && this.upload.name === this.componentData.componentImageUrl ? null : this.upload;
        this.componentData.componentImageUrl =
          this.componentData.componentImageUrl && !this.upload ? null : this.componentData.componentImageUrl;
        this.componentData.componentDocument =
          this.uploadDocument && this.uploadDocument.name === this.componentData.componentDocumentUrl ? null : this.uploadDocument;
        this.componentData.componentDocumentUrl =
          this.componentData.componentDocumentUrl && !this.uploadDocument ? null : this.componentData.componentDocumentUrl;
        this.$nextTick(() => {
          mutate(this.componentData);
        });
      }
    },
  },
};
