var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('alert',{attrs:{"error":_vm.error}}),_c('v-row',{staticClass:"mb-6",attrs:{"no-gutters":"","justify":"end"}},[(_vm.showColumnPicker)?_c('budget-calculation-info'):_vm._e(),(_vm.showColumnPicker && _vm.displayedItems.length)?_c('data-table-columns',{attrs:{"headers":_vm.availableHeaders,"uId":_vm.uId},model:{value:(_vm.selectedHeaders),callback:function ($$v) {_vm.selectedHeaders=$$v},expression:"selectedHeaders"}}):_vm._e(),(_vm.showImportButton)?_c('modal',{attrs:{"title":_vm.importPopupData.importPopupHeader,"width":_vm.canCreateComponent ? _vm.importPopupData.importPopupWidth + 'px' : '588px',"noClose":!_vm.importPopupData.canClosePopup,"persistent":!_vm.canCreateComponent},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showImportButton),expression:"showImportButton"}],staticClass:"ml-6",attrs:{"color-type":"accent","width":"257"}},'btn',attrs,false),on),[_vm._v("Import Components")])]}},{key:"default",fn:function(ref){
var close = ref.close;
return [(_vm.canCreateComponent)?_c('import-components-form',{attrs:{"fundingSourceId":_vm.fundingSourceId,"planId":_vm.planId},on:{"import-finish":_vm.changeImportPopup,"done":close}}):_c('div',{staticClass:"pr-16 pl-16 text-center"},[_c('div',[_vm._v(_vm._s(_vm.importComponentWarning))]),_c('div',{staticClass:"mt-7"},[_c('btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isFacilitiesFilled),expression:"!isFacilitiesFilled"}],class:{ 'mr-7': !_vm.isFinancialFilled },attrs:{"width":"192px"},on:{"click":function($event){return _vm.goToTab('Facilities', close)}}},[_vm._v("Go to Facilities")]),_c('btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isFinancialFilled),expression:"!isFinancialFilled"}],attrs:{"width":"192px"},on:{"click":function($event){return _vm.goToTab('Financial', close)}}},[_vm._v("Go to Financial")])],1)])]}}],null,false,3455988923)}):_vm._e(),(_vm.showAddButton)?_c('modal',{attrs:{"title":"Add Component","width":_vm.canCreateComponent ? '1000px' : '588px',"noClose":!_vm.canCreateComponent,"persistent":!_vm.canCreateComponent},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showAddComponentBtn),expression:"showAddComponentBtn"}],staticClass:"ml-6",attrs:{"width":"180"}},'btn',attrs,false),on),[_vm._v("Add Component")])]}},{key:"default",fn:function(ref){
var close = ref.close;
return [(_vm.canCreateComponent)?_c('component-of-need-form',{attrs:{"plan":_vm.plan,"facilities":_vm.facilities,"btnLabel":"Add"},on:{"done":close}}):_c('div',{staticClass:"pr-16 pl-16 text-center"},[_c('div',[_vm._v(_vm._s(_vm.createComponentWarning))]),_c('div',{staticClass:"mt-7"},[_c('btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isFacilitiesFilled),expression:"!isFacilitiesFilled"}],class:{ 'mr-7': !_vm.isFinancialFilled },attrs:{"width":"192px"},on:{"click":function($event){return _vm.goToTab('Facilities', close)}}},[_vm._v("Go to Facilities")]),_c('btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isFinancialFilled),expression:"!isFinancialFilled"}],attrs:{"width":"192px"},on:{"click":function($event){return _vm.goToTab('Financial', close)}}},[_vm._v("Go to Financial")])],1)])]}}],null,false,1766560457)}):_vm._e()],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('data-table-vs',{staticClass:"component-list",attrs:{"items":_vm.displayedItems,"headers":_vm.displayedHeaders,"item-key":"id","height":"611px","loading":_vm.loading,"resetBtnParams":{ show: true, isAbsolute: true },"noDataText":"No components"},on:{"filtered":function($event){return _vm.$emit('filtered', $event)},"mounted":_vm.setFlagFilterDefault,"reset":_vm.resetFlagFilter},scopedSlots:_vm._u([{key:"filter.flag",fn:function(ref){
var item = ref.item;
var callback = ref.callback;
return [_c('div',{staticClass:"select-wrapper",staticStyle:{"width":"97px"}},[_c('v-select',{ref:"flag_filter",attrs:{"outlined":"","dense":"","multiple":"","full-width":"","hide-details":"","clearable":"","items":['Marked', 'Unmarked'],"default":item},on:{"change":function($event){return _vm.onFlagFilter(item, callback)}},model:{value:(_vm.flagFilterModel),callback:function ($$v) {_vm.flagFilterModel=$$v},expression:"flagFilterModel"}})],1)]}},{key:"item.flag",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.themes.light.accent},on:{"click":function($event){_vm.showEditBtn && _vm.onFlagClick(item)}}},[_vm._v(_vm._s(item.flag ? 'fas' : 'far')+" fa-flag")])],1)]}},{key:"item.softCost",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{class:{ '-mark-checkbox': item.customSoftCost && item.customSoftCost !== _vm.plan.financialInfo.softCostPercentage },attrs:{"value":item.softCost,"ripple":false,"readonly":""}})]}},{key:"item.componentImageUrl",fn:function(ref){
var item = ref.item;
return [(item.componentImageUrl)?_c('a',{attrs:{"href":item.componentImageUrl,"target":"_blank"}},[_vm._v("Link to Photo")]):_vm._e()]}},{key:"item.componentDocumentUrl",fn:function(ref){
var item = ref.item;
return [(item.componentDocumentUrl)?_c('a',{attrs:{"href":item.componentDocumentUrl,"target":"_blank"}},[_vm._v("Link to Document")]):_vm._e()]}},(_vm.showActions)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('actions-menu',{attrs:{"actions":item.actions}})]}}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }