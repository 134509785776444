import CLONE_COMPONENT_OF_NEEDS_MUTATION from '@/graphql/mutations/cloneComponentOfNeed.gql';
import COMPONENTS_OF_NEEDS from '@/graphql/queries/componentsOfNeed.gql';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import DELETE_COMPONENT_OF_NEED_MUTATION from '@/graphql/mutations/deleteComponentOfNeed.gql';
import PERMISSIONS from '@/enums/permissions';
import accessByPermissions from '@/services/userPermissions';
import ActionsMenu from '@/components/ActionsMenu/ActionsMenu.vue';
import Btn from '@/components/Btn/Btn.vue';
import BudgetCalculationInfo from '@/components/BudgetCalculationInfo/BudgetCalculationInfo.vue';
import CompletedComponentForm from '@/components/CompletedComponentForm/CompletedComponentForm.vue';
import componentOfNeedsHeaders from '@/enums/componentOfNeedsHeaders';
import ComponentOfNeedForm from '@/components/ComponentOfNeedForm/ComponentOfNeedForm.vue';
import DataTableColumns from '@/components/DataTableColumns/DataTableColumns.vue';
import DataTableVS from '@/components/DataTableVS/DataTableVS.vue';
import ImportComponentsForm from './ImportComponentsForm/ImportComponentsForm.vue';
import eventHub from '@/utils/eventHub';
import flaggedComponentMixin from '@/mixins/flaggedComponentMixin';
import Modal from '@/components/Modal/Modal.vue';
import { calculateBudget } from '@/services/componentBudget';
import { COMPONENT_STATUS } from '@/enums/componentStatus';

export default {
  name: 'ComponentsOfNeedTable',
  components: {
    'data-table-vs': DataTableVS,
    ActionsMenu,
    Btn,
    BudgetCalculationInfo,
    CompletedComponentForm,
    ComponentOfNeedForm,
    DataTableColumns,
    Modal,
    ImportComponentsForm,
  },
  mixins: [flaggedComponentMixin],
  props: {
    plan: { type: Object, default: null },
    facilities: { type: Array, default: () => [] },
    uId: { type: String, default: '' },
    showActions: { type: Boolean, default: true },
    showAddButton: { type: Boolean, default: true },
    showColumnPicker: { type: Boolean, default: true },
  },
  apollo: {
    componentsOfNeed: {
      query: COMPONENTS_OF_NEEDS,
      variables() {
        return {
          planId: (this.plan && this.plan.id) || null,
          status: COMPONENT_STATUS.OPEN,
        };
      },
      skip() {
        return !this.plan || !this.plan.id;
      },
      error(error) {
        this.error = error.networkError ? { ...error, message: 'Something went wrong! Could not load plan components.' } : error;
      },
    },
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
  },
  data: () => ({
    currentUser: null,
    componentsOfNeed: null,
    error: null,
    selectedHeaders: null,
    flagFilterModel: [],
    importPopupData: {
      importPopupHeader: 'Import Components',
      importPopupWidth: 480,
      canClosePopup: true,
    },
  }),
  created() {
    eventHub.$on('refresh-components-of-need', () => {
      this.$apollo.queries.componentsOfNeed.refresh();
    });
  },
  computed: {
    fundingSourceId() {
      let sortArray = this.plan.financialInfo.fundingSources.sort((prev, next) => +prev.id - +next.id);
      return sortArray[0].id || null;
    },
    planId() {
      return this.plan?.id || null;
    },
    availableHeaders() {
      const result = this.headers.filter(h => {
        return !h.permissions || h.permissions.some(p => accessByPermissions(p, this.currentUser));
      });
      return result;
    },
    canCreateComponent() {
      return this.isFacilitiesFilled && this.isFinancialFilled;
    },
    components() {
      return JSON.parse(JSON.stringify(this.componentsOfNeed)) || [];
    },
    createComponentWarning() {
      if (!this.isFacilitiesFilled && !this.isFinancialFilled) {
        return 'You can not Add Components without Facilities and Funding Source. Please fill in Facilities and Financial before adding components.';
      }
      if (!this.isFacilitiesFilled) {
        return 'You can not Add Component without Facilities. Please fill in Facilities before adding components.';
      }
      if (!this.isFinancialFilled) {
        return 'You can not Add Component without Funding Source. Please fill in Facilities and Financial before importing components.';
      }
    },
    importComponentWarning() {
      if (!this.isFacilitiesFilled && !this.isFinancialFilled) {
        return 'You can not Import Components without Facilities and Funding Source. Please fill in Facilities and Financial before importing components.';
      }
      if (!this.isFacilitiesFilled) {
        return 'You can not Import Components without Facilities. Please fill in Facilities before importing components.';
      }
      if (!this.isFinancialFilled) {
        return 'You can not Import Components without Funding Source. Please fill in Financial before importing components.';
      }
    },
    displayedHeaders() {
      return this.availableHeaders.filter((h, index) => {
        return this.selectedHeaders ? this.selectedHeaders.includes(index) : h;
      });
    },
    displayedItems() {
      return this.components
        ? this.components.map(component => {
            const budget = calculateBudget(component, this.plan);
            const row = {
              ...component,
              facilityName: (component.facility && component.facility.name) || '',
              systemName: (component.system && component.system.name) || '',
              componentName: (component.component && component.component.name) || '',
              unitName: (component.unit && component.unit.name) || '',
              conditionName: (component.conditionAssessment && component.conditionAssessment.name) || '',
              fundingSourceName: (component.fundingSource && component.fundingSource.name) || '',
              ...budget,
            };
            if (this.showActions) {
              row.actions = [
                accessByPermissions(PERMISSIONS.EDIT_PLAN_COMPONENT, this.currentUser) && {
                  name: 'Edit',
                  modal: {
                    type: 'common',
                    props: {
                      title: 'Edit Component',
                      width: '1000px',
                    },
                    content: {
                      componentName: ComponentOfNeedForm,
                      props: {
                        btnLabel: 'Update',
                        plan: this.plan,
                        facilities: this.facilities,
                        formData: component,
                        isEdit: true,
                      },
                    },
                  },
                },
                accessByPermissions(PERMISSIONS.EDIT_PLAN_COMPONENT, this.currentUser) && {
                  name: 'Complete',
                  modal: {
                    type: 'common',
                    props: {
                      title: 'Complete Component',
                      width: '480px',
                    },
                    content: {
                      componentName: CompletedComponentForm,
                      props: {
                        plan: this.plan,
                        data: component,
                      },
                    },
                  },
                },
                accessByPermissions(PERMISSIONS.EDIT_PLAN_COMPONENT, this.currentUser) && {
                  name: 'Clone',
                  modal: {
                    props: {
                      mutation: CLONE_COMPONENT_OF_NEEDS_MUTATION,
                      variables: { id: component.id },
                      title: 'Clone Component',
                      message: 'Are you sure you want to clone the component?',
                      okBtn: { label: 'Clone' },
                      update: this.onCloneComponent(component),
                    },
                  },
                },
                accessByPermissions(PERMISSIONS.DELETE_PLAN_COMPONENT, this.currentUser) && {
                  name: 'Delete',
                  modal: {
                    props: {
                      mutation: DELETE_COMPONENT_OF_NEED_MUTATION,
                      variables: { id: component.id },
                      title: 'Delete Component',
                      message: 'Are you sure you want to delete the component?',
                      okBtn: { label: 'Delete' },
                      update: this.onDelete(component),
                    },
                  },
                },
              ];
            }
            return row;
          })
        : [];
    },
    flagFilterIcon() {
      if (this.flagFilterModel.length === 0) {
        return 'fa-star-half-alt';
      }
      if (this.flagFilterModel[0] === true) {
        return 'fas fa-star';
      }
      if (this.flagFilterModel[0] === false) {
        return 'far fa-star';
      }
    },
    headers() {
      const headers = [...componentOfNeedsHeaders.ACTIVE];
      this.showActions &&
        headers.push({
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '53px',
          fixed: true,
          permissions: [PERMISSIONS.EDIT_PLAN_COMPONENT, PERMISSIONS.DELETE_PLAN_COMPONENT],
        });
      return headers;
    },
    isFacilitiesFilled() {
      return this.facilities?.length;
    },
    isFinancialFilled() {
      return (
        this.plan.financialInfo?.planStartYear && this.plan.financialInfo.typeOfPlan && this.plan.financialInfo.fundingSources.length > 0
      );
    },
    loading() {
      return this.$apollo.queries.componentsOfNeed.loading;
    },
    showAddComponentBtn() {
      return accessByPermissions(PERMISSIONS.ADD_PLAN_COMPONENT, this.currentUser);
    },
    showImportButton() {
      return !this.displayedItems.length && !this.loading;
    },
    showEditBtn() {
      return accessByPermissions(PERMISSIONS.EDIT_PLAN_COMPONENT, this.currentUser);
    },
    showDeleteBtn() {
      return accessByPermissions(PERMISSIONS.DELETE_PLAN_COMPONENT, this.currentUser);
    },
  },
  methods: {
    goToTab(tabName, close) {
      close();
      eventHub.$emit('go-to-tab', tabName);
    },
    readQuery(store, status) {
      return store.readQuery({
        query: COMPONENTS_OF_NEEDS,
        variables: {
          planId: this.plan.id,
          status: status,
        },
      });
    },
    writeQuery(store, status, data) {
      return store.writeQuery({
        query: COMPONENTS_OF_NEEDS,
        variables: {
          planId: this.plan.id,
          status: status,
        },
        data,
      });
    },
    onCloneComponent() {
      return (store, { data: { cloneComponentOfNeed } }) => {
        if (cloneComponentOfNeed) {
          const data = this.readQuery(store, cloneComponentOfNeed.status);
          if (data) {
            data.componentsOfNeed.unshift(cloneComponentOfNeed);
            this.writeQuery(store, cloneComponentOfNeed.status, data);
          }
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: 'Component was cloned successfully!',
          });
        }
      };
    },
    onDelete(item) {
      return (store, { data: { deleteComponentOfNeed } }) => {
        if (deleteComponentOfNeed) {
          const data = this.readQuery(store, item.status);
          if (data) {
            data.componentsOfNeed = data.componentsOfNeed.filter(component => {
              return component.id !== item.id;
            });
            this.writeQuery(store, item.status, data);
          }
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: 'Component was deleted successfully!',
          });
        }
      };
    },
    changeImportPopup(dataPopup) {
      this.importPopupData.importPopupHeader = dataPopup.header;
      this.importPopupData.importPopupWidth = dataPopup.width;
      this.importPopupData.canClosePopup = dataPopup.close;
      if (dataPopup.components) {
        let data = this.$apolloProvider.defaultClient.readQuery({
          query: COMPONENTS_OF_NEEDS,
          variables: {
            planId: this.planId || null,
            status: COMPONENT_STATUS.OPEN,
          },
        });
        data.componentsOfNeed = dataPopup.components;
        this.$apolloProvider.defaultClient.writeQuery({
          query: COMPONENTS_OF_NEEDS,
          variables: {
            planId: this.planId || null,
            status: COMPONENT_STATUS.OPEN,
          },
          data: data,
        });
      }
    },
  },
};
