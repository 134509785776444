import COMPLETE_COMPONENT_OF_NEED_MUTATION from '@/graphql/mutations/completeComponentOfNeed.gql';
import eventHub from '@/utils/eventHub';
import COMPONENTS_OF_NEEDS from '@/graphql/queries/componentsOfNeed.gql';
import { COMPONENT_STATUS } from '@/enums/componentStatus';
import Btn from '@/components/Btn/Btn.vue';
import NumberTextField from '@/components/NumberTextField/NumberTextField.vue';
import validateRules from '@/services/validateRules';
import { generateRangeArray } from '@/utils/generator';
import Upload from '@/components/Upload/Upload.vue';

export default {
  name: 'CompleteComponentForm',
  components: { Btn, NumberTextField, Upload },
  props: {
    plan: {
      type: Object,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  data: vm => {
    const startYear = vm.plan && vm.plan.financialInfo && parseInt(vm.plan.financialInfo.planStartYear);
    const years = startYear ? generateRangeArray(startYear, startYear + 19) : [];
    return {
      ...validateRules,
      COMPLETE_COMPONENT_OF_NEED_MUTATION,
      component: (vm.data && { ...vm.data }) || {
        yearCompleted: null,
        actualCost: null,
        componentNotes: null,
        componentCompleteDocument: null,
      },
      uploadDocument: null,
      yearCompletedRules: [v => (v !== null && v !== '') || 'Year completed is Required'],
      years: years,
      actualCostRules: [validateRules.positiveNumber, validateRules.floatNumber(2)],
    };
  },
  watch: {
    data(value) {
      this.component = { ...value };
    },
  },
  computed: {
    mutationInput() {
      const input = {
        id: this.component.id,
        yearCompleted: this.component.yearCompleted,
        actualCost: this.component.actualCost,
        componentNotes: this.component.componentNotes,
        componentCompleteDocument: this.component.componentCompleteDocument,
      };
      return input;
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onFileChange() {
      this.$set(this.component, 'componentCompleteDocument', this.uploadDocument || null);
    },
    onSubmit(mutate) {
      if (this.validate()) {
        mutate(this.component);
      }
    },
    updateComponents(store, { data: { completedComponentOfNeed } }) {
      if (completedComponentOfNeed) {
        let data = store.readQuery({
          query: COMPONENTS_OF_NEEDS,
          variables: {
            planId: this.plan.id,
            status: COMPONENT_STATUS.OPEN,
          },
        });
        if (data) {
          data.componentsOfNeed = data.componentsOfNeed.filter(c => c.id !== completedComponentOfNeed.id);
          store.writeQuery({
            query: COMPONENTS_OF_NEEDS,
            variables: {
              planId: this.plan.id,
              status: COMPONENT_STATUS.OPEN,
            },
            data,
          });
        }
        try {
          data = store.readQuery({
            query: COMPONENTS_OF_NEEDS,
            variables: {
              planId: this.plan.id,
              status: completedComponentOfNeed.status,
            },
          });
          if (data) {
            data.componentsOfNeed.unshift(completedComponentOfNeed);
            store.writeQuery({
              query: COMPONENTS_OF_NEEDS,
              variables: {
                planId: this.plan.id,
                status: completedComponentOfNeed.status,
              },
              data,
            });
          }
        } catch (e) {
          //
        }
        eventHub.$emit('show-snackbar', {
          color: 'success',
          text: `Status of the component was changed successfully!`,
        });
      } else {
        eventHub.$emit('show-snackbar', {
          color: 'error',
          text: `Error! Something went wrong... Please try later!`,
        });
      }
    },
  },
};
